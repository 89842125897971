<template>
  <div>
    <vTitulo> Ajuda </vTitulo>
    <v-row class="justify-center align-center">
      <v-col cols="12" sm="8" class="order-sm-1 order-2">
        <div class="pa-4">
          <v-text-field
            placeholder="Pesquisar duvida"
            v-model="search"
            solo
            hide-details
            class="rounded searchInput d-block d-sm-inline-block"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="order-sm-2 order-1 text-center text-sm-right"
      >
        <div class="contemImagem mt-n16">
          <div class="pa-5 text-center text-sm-right mb-sm-0 mb-n5">
            <img src="@/assets/dash/help-image.svg" class="imagem mx-auto" />
          </div>
        </div>
      </v-col>
    </v-row>

    <div>
      <div
        v-for="(item, index) in renderHelpers"
        :key="index"
        class="pa-4 white ma-4 rounded-lg lowShadow"
      >
        <div class="d-grid grid-col-auto-1 justify-center align-center pa-3">
          <div
            class="
              primary
              rounded
              pa-0
              white--text
              font-weight-bold
              d-flex
              justify-center
              align-center
              sizeIndex
            "
          >
            {{ item.indice }}
          </div>
          <div class="pa-3 px-5 font-weight-bold text-subtitle-1">
            {{ item.titulo }}
          </div>
        </div>
        <div
          v-if="item.isHTML"
          class="pa-3 grey--text text--darken-3"
          v-html="item.mensagem"
        ></div>
        <div v-else class="pa-3 grey--text text--darken-3">
          {{ item.mensagem }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.searchInput {
  outline: none;
}
.sizeIndex {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}
.contemImagem {
  text-align: right;
}
.imagem {
  width: 150px;
}
</style>

<script>
export default {
  data() {
    return {
      search: "",
      helpers: [
        {
          indice: 1,
          titulo: "O QUE É O APP DA TURMA ?",
          mensagem:
            "Nossa plataforma nasceu com o propósito de facilitar a organização financeira dos mais diversos tipos de grupos, associações, turmas de formandos, etc. Com o App da Turma você cria a sua turma ou seu evento, cadastra os participantes, cria as mensalidades para juntar o dinheiro de todos e ainda tem a possibilidade de fazer os pagamentos de despesas direto na conta dos fornecedores contratados, o que pode gerar uma boa economia. Tudo com segurança e transparência para todos os participantes."
        },
        {
          indice: 2,
          titulo: "COMO USAR O APP DA TURMA NO CELULAR ?",
          isHTML: true,
          mensagem:
            "Além do uso acessando o site www.appdaturma.com.br, o App da Turma pode ser usado com a mesma experiência de um App tradicional, com ícone na tela e tudo, mas com a vantagem de não precisar ser instalado, e isso é ótimo, porque você não precisa usar a memória do seu celular. <br/><br/> Se você for usar o iOS, basta acessar appdaturma.com.br e, depois de entrar com login e senha, clique na setinha que aparece na tela, você seleciona a opção “Adicionar à Tela de Início”. (veja nosso vídeo com as orientações - colocar o link do vídeo) <br/><br/> No Android é bem parecido, você vai nos 3 pontinhos que aparecem no canto superior direito da tela, e seleciona a opção “Adicionar à tela Inicial”.<br/><br/> Agora a nossa dica mesmo é que você acesse a plataforma através do desktop, assim a sua experiência fica mais completa."
        },
        {
          indice: 3,
          titulo: "COMO CRIAR UMA CONTA NO APP DA TURMA ?",
          isHTML: true,
          mensagem:
            "Tudo se inicia com o cadastro de uma pessoa da turma, que inicialmente será o(a) administrador(a).Iniciar o cadastro é muito simples, e você faz em 5 passos:<br/><br/> 1) Pra começar, você tem duas opções: ou clicar em Acessar e, em seguida, ir em “Não possui conta? Cadastre-se.”, ou ir direto no botão “Quero começar”, na página inicial. <br/><br/> 2) Preencha os campos em branco com os dados de Nome, E-mail e Telefone, e selecione o botão “Enviar”. Você vai receber um e-mail de confirmação. <br/><br/> 3) Ative a sua conta através do link que receber via e-mail. <br/><br/> 4) Complete o seu cadastro e revise as informações. <br/><br/> 5) Pronto, sua conta está criada e você já pode cadastrar o seu evento!"
        },
        {
          indice: 4,
          titulo: "COMO CRIAR UMA TURMA ?",
          isHTML: true,
          mensagem: `Criar uma turma na Plataforma é rápido, mas lembre-se, para criar um evento, antes de tudo, você precisa ter uma conta no App da Turma. <br/><br/>
            1) O primeiro passo, após acessar sua conta na plataforma, é clicar no canto superior direto da tela na opção “Criar”. Quem cria uma turma, torna-se automaticamente o administrador dela.<br/><br/>
            2) Na próxima etapa, você escolhe o tipo do seu evento, que pode ser: Associação, Casamento, Corporativo, Formatura e Outro.<br/><br/>
            3) Você preenche os campos solicitados de acordo com o tipo do seu evento.<br/><br/>
            4) Criado o evento, aparece um link, que você encaminha para que todos os participantes possam fazer o seu cadastro. Após cada convidado se cadastrar, o administrador faz a validação do participante. Se tiver o e-mail das pessoas que vai convidar, pode-se também enviar um convite dessa forma. Acesse a área de Participantes, e selecione “Adicionar novos participantes.” Agora preencha os dados solicitados e envie o convite. O convidado vai receber um e-mail e, quando aceitar e preencher o cadastro, já estará automaticamente cadastrado na turma, sem precisar da validação do administrador.`
        },
        {
          indice: 5,
          titulo: "EM QUANTAS TURMAS POSSO ESTAR CADASTRADO ?",
          mensagem:
            "Não existe um limite de grupos ou eventos para você estar cadastrado. Da mesma forma, você pode ser apenas participante em um evento, estar na comissão de aprovação em outro, e ser administrador em um terceiro evento que estiver cadastrado. Tudo com somente um cadastro."
        },
        {
          indice: 6,
          titulo: "QUAL É A MINHA SEGURANÇA NO APP DA TURMA ?",
          mensagem:
            "O  App da Turma é super seguro. Cada grupo ou evento cadastrado possui sua própria conta, e todos os valores arrecadados são movimentados somente quando um pagamento é solicitado e autorizado pela comissão de aprovação do grupo."
        },
        {
          indice: 7,
          titulo: "O APP DA TURMA COBRA MENSALIDADE ?",
          isHTML: true,
          mensagem: `Você pode fazer o cadastro no App da Turma de forma gratuita. Somente para começar a movimentar valores dentro da plataforma que é necessário escolher um plano de tarifas, que são superacessíveis. <br/><br/>
          Temos 3 planos diferentes de mensalidades:<br/><br/>
          1. No plano básico estão inclusos a utilização full da plataforma bem como os recebimentos dos participantes por boletos ou PIX.<br/><br/>
          2. No plano Plus, além dos benefícios do plano básico, também não são cobrados adicionais nas movimentações de saída do dinheiro para pagar os fornecedores diretamente pela plataforma. <br/><br/>
          3. Agora a cereja do bolo é o plano Top, o nosso plano mais completo! Escolhendo essa opção, além dos benefícios dos planos básico e plus, você tem acesso a nossa assessoria técnica, com um gestor sempre à disposição, e com reuniões online para elaborar os cronogramas e assessorar a comissão na organização dos eventos e contratações dos serviços. Evite também os constrangimentos, nós mesmos fazemos a cobrança ativa dos participantes inadimplentes. <br/><br/>
          Agora é só consultar os valores e escolher o plano que mais combina com você para começar a aproveitar tudo o que o App da Turma pode oferecer!`
        },
        {
          indice: 8,
          titulo: "POR QUE PAGAR OS FORNECEDORES PELO APP DA TURMA ?",
          isHTML: true,
          mensagem: `Esse é um dos principais motivos da criação da plataforma, gerar ECONOMIA E AUTONOMIA para a própria turma fazer seus pagamentos, sem precisar de intermediários. Com o saldo da conta da turma ou evento no App da Turma é possível solicitar o pagamento de boletos ou para fazer transferências diretamente para os fornecedores que você cadastrou. <br/><br/>
          Sempre que fizer um pagamento ou devolução para um participante, primeiro você precisa ter um fornecedor cadastrado. 
           <br/><br/>
          Para isso, você acessa a área de Fornecedores, e depois seleciona “Novo Fornecedor”. Serão solicitados os dados cadastrais da Empresa, além dos dados bancários. Esses dados ficam salvos e, a partir deles, você consegue fazer a programação dos pagamentos. <br/><br/>
          É importante lembrar que, por segurança, qualquer pagamento só é liberado com a aprovação da maioria dos integrantes que fizerem parte da comissão de autorização.`
        },
        {
          indice: 9,
          titulo:
            "JÁ COMECEI A ARRECADAÇÃO DO MEU EVENTO, POSSO USAR O APP DA TURMA ?",
          mensagem: `Claro que pode, inclusive temos a função de “depósito avulso, que é destinada à dinheiro já arrecadado. A gente sabe que algumas necessidades surgem no meio do processo, e estamos prontos para ajudar. Nesse caso, basta você entrar em contato conosco por e-mail ou WhatsApp. Assim poderemos entender a sua necessidade e adequar as futuras parcelas / pagamentos do seu evento. `
        },
        {
          indice: 10,
          titulo:
            "TODOS OS PARTICIPANTES ACESSAM A TODAS AS INFORMAÇÕES FINANCEIRAS ?",
          mensagem:
            "Sim, prezamos pela transparência. Além de todos receberem prestação de contas, todas as informações estão acessíveis para que todos os participantes possam acompanhar as movimentações financeiras do evento a qualquer instante."
        },
        {
          indice: 11,
          titulo: "POSSO USAR A CALCULADORA PARA QUALQUER EVENTO ?",
          mensagem: `Sim! Muito embora ela foi projetada para fazer cálculos de formatura, o valor de qualquer evento pode ser estimado com o uso da calculadora. <br/><br/>
          Nela, você informa o número de pessoas pagantes, o dia do seu evento e seleciona se quer fazer o cálculo usando o valor de uma parcela já definida ou com base no valor total do evento.<br/><br/>
          Com essas informações, o próximo passo é informar seus dados de nome, telefone e e-mail, e selecionar “Quero saber mais”. As informações aparecem na sua tela, e, além disso, você recebe um e-mail com os dados solicitados e um convite para ativar sua conta no App da Turma.`
        },
        {
          indice: 12,
          titulo: "POSSO SACAR OS VALORES EM DINHEIRO ?",
          mensagem:
            "Não. Todos os valores movimentados no App da Turma são feitos através de transferência bancária ou pagamento de boletos, sempre para pessoas jurídicas."
        },
        {
          indice: 13,
          titulo:
            "TEM UMA DATA LIMITE PARA USAR O DINHEIRO QUE ESTÁ NA PLATAFORMA ?",
          mensagem:
            "Não, você pode usar o App da Turma para eventos que ocorrerão no curto ou longo prazo. Não tem um período limite, mas sempre indicamos começar a organização financeira com o máximo de antecedência possível. 😊 "
        },
        {
          indice: 14,
          titulo:
            "E SE UM DOS PARTICIPANTES ATRASAR O PAGAMENTO DE UMA PARCELA ?",
          mensagem:
            "Sabemos que atrasos podem acontecer. Nesse caso, os valores podem ser ajustados e as parcelas podem ser adequadas para manter a saúde financeira do evento. Temos também o serviço de cobrança ativo para ajudar a turma, consulte nossas opções."
        },
        {
          indice: 15,
          titulo: "O QUE ACONTECE SE MEU EVENTO FOR CANCELADO ?",
          mensagem:
            "Em caso de cancelamento, devolvemos 100% do valor arrecadado. Sem burocracias."
        }
      ]
    };
  },
  computed: {
    renderHelpers() {
      return this.helpers.filter(item => {
        let itemTitulo = item.titulo.toUpperCase();
        let itemDescricao = item.mensagem.toUpperCase();
        let search = this.search.toUpperCase();
        if (itemTitulo.match(search) || itemDescricao.match(search)) {
          return true;
        }
      });
    }
  },
  methods: {
    buscarHelpers() {
      //
    }
  }
};
</script>
